<template>
    <div>
        <PageTitle title="医生简介" fixed :backTo="'/consultOnline/1/' + doctorInfo.departmentid + '/' + doctorInfo.jobnumber"></PageTitle>

        <!--<div style="width: 85%;margin: 15px auto;">-->
        <List>
            <ListItem>
                <template #top>
                    <div>
                        <img :src="doctorInfo.profile_photo || defaultDoctorPicture"/>
                        <h4>{{ doctorInfo.doctorname }}</h4>
                        <h5>{{ doctorInfo.department }}/{{ doctorInfo.area }}</h5>
                    </div>
                    <section><span>专家介绍：</span>{{ doctorInfo.description }}</section>
                    <section><span>诊疗范围：</span>{{ doctorInfo.clinic }}</section>
                </template>
            </ListItem>
        </List>
            <!--<van-button type="primary" block @click="treatmentAgain()">复诊开药</van-button>-->
        <!--</div>-->
    </div>
</template>

<script>
    // @ is an alias to /src
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';

    import { getDoctorInfo } from '@/api/hospital.js';

    export default {
        components: {
            PageTitle,
            List,
            ListItem
        },
        data(){
          return {
              defaultDoctorPicture: require('../assets/default_doctor.png'),
              doctorInfo: {}
          }
        },
        beforeCreate(){
            getDoctorInfo(this.$route.params.doctorId, this.$route.params.departmentId, localStorage.getItem('applyType') || '云门诊').then(
                (data) => {
                    this.doctorInfo = data;
                },() => {}
            )
        }
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";
    .listContainer{
        margin-top: 20px;

        .infoContainer{
            padding: 10px 15px;

            .top{
                div{
                    text-align: center;
					
					img{
						border-radius: 80px;
						width: 160px;
					}

                    h4,h5{
                        margin: 5px 0;
                    }
                }
                section{
                    font-size: @font-size-md;
                    margin-top: 2vh;

                    span{
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>
